export interface Post {
    title: string;
    body: string;
    url: string;
    id: string;
    date: string;
    topics: number[];
    sentiment: string;
}

export interface Summary {
    startDate: string;
    endDate: string;
    positive: string;
    negative: string;
    neutral: string;
    recs: string;
}

export interface Topic {
    label: string;
    id: number;
    arcColor: string;
    bgColor: string;
}

export interface TopicSummary {
    topic_id: number;
    insights: string;
    recs: string;
}

export const TopicMap: {[key: number]: Topic;} = {
    1: {
        label: 'Compatibility & Integration',
        arcColor: "rgb(239 68 68)",
        bgColor: "rgb(254 202 202)",
        id: 1
    },
    2: {
        label: 'Performance Comparison & Benchmarking',
        arcColor: "rgb(59 130 246)",
        bgColor: "rgb(191 219 254)",
        id: 2
    },
    3: {
        label: 'Quantization & Precision',
        arcColor: "rgb(20 184 166)",
        bgColor: "rgb(153 246 228)",
        id: 3
    },
    4: {
        label: 'Usability & Iteration Speed',
        arcColor: "rgb(139 92 246)",
        bgColor: "rgb(221 214 254)",
        id: 4
    },
    5: {
        label: 'DeepStream SDK & Jetson Modules',
        arcColor: "rgb(132 204 22)",
        bgColor: "rgb(217 249 157)",
        id: 5
    },
    6: {
        label: 'Documentation & Education',
        arcColor: "rgb(234,88,12)",
        bgColor: "rgb(254,215,170)",
        id: 6
    }
}
