import React from "react";
import type { PageProps } from "gatsby";
import WithAuth from "../components/user/WithAuth";
import BaseLayout from "../components/layout/BaseLayout";
import DemoDashboardViewPage from "../demo/DemoDashboardViewPage";

const DashboardViewRoute = (props: PageProps) => {

    return (
        <WithAuth>
            <BaseLayout {...props}>
                <DemoDashboardViewPage {...props} />
            </BaseLayout>
        </WithAuth>
    )

};


export const Head = () => {
    return (
        <>
            <title>Runic | Dashboard View</title>
        </>
    )
}


export default DashboardViewRoute;
