import React, {useEffect, useState} from "react";
import TileRounded from "../components/layout/TileRounded";
import { PieChart } from "react-minimal-pie-chart";
import {posts} from "./data";
import {TopicMap, Topic} from "./models";
import OpenAiResponseText from "../components/openai/OpenAiResponseText";
import {topicSummaries} from "./topics";

interface TopicWithMentions extends Topic {
    mentions: number;
}

type TopicsMapWithData = { [key: number]: TopicWithMentions };


const DemoTopics = () => {

    const [doDisplayMetrics, setDoDisplayMetrics] = useState<boolean>(true);
    const [topicSelected, setTopicSelected] = useState<number>(1);


    const getCharts = (): JSX.Element[] | JSX.Element => {

        const defaultClass = "flex p-4 pt-6"
        const manyTopicsClass = "grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4 pt-4"

        const topicsMapWithData: TopicsMapWithData = {}
        for (const topic of Object.values(TopicMap)) {
            topicsMapWithData[topic.id] = {
                ...topic,
                mentions: 0
            }
        }

        const charts = [];
        let index = 0;
        let totalMentions = 0
        posts.forEach(post => {
            totalMentions++
            if (post.topics) {
                post.topics.forEach(topicId => {
                    topicsMapWithData[topicId].mentions++
                })
            }
        })
        for (const topic of Object.values(topicsMapWithData).sort((a, b) => {
            return b.mentions - a.mentions
        })) {
            const percentage = totalMentions ? ((topic.mentions / totalMentions) * 100) : 0
            const empty = 100 - percentage;
            charts.push(
                <div key={`topic-${topic.id}`}>
                    <div className={"px-3 pb-2"}>
                        <PieChart
                            data={[
                                { value: percentage, color: topic.arcColor, display: true },
                                { value: empty, color: topic.bgColor },
                            ]}
                            totalValue={100}
                            startAngle={-90}
                            lineWidth={20}
                            label={({ dataEntry }) =>
                                dataEntry.display ? `${dataEntry.value.toFixed(2)}%` : null
                            }
                            labelStyle={{
                                fontSize: "12px",
                                fontFamily: "sans-serif",
                                fill: "black",
                            }}
                            labelPosition={0}
                        />
                    </div>
                    <div className={"text-sm text-center text-gray-500"}>
                        <span>{topic.label}</span>
                    </div>
                </div>
            );
            index++
        }
        return (<div className={manyTopicsClass}>{charts}</div>);
    }

    const getTopicIdOptions = () => {
        const options = []
        const topics = Object.values(TopicMap)
        if (!topics || topics.length === 0) {
            return options
        }
        for (const topic of topics) {
            options.push(
                <option key={`${topic.id}`} value={`${topic.id}`}>{topic.label}</option>
            )
        }
        return options
    }

    const getSummary = (): JSX.Element[] | JSX.Element => {
        return (
            <div>
                <div>
                    <label
                        htmlFor={'topic-id-selection'}
                        className="block text-sm font-medium text-gray-700"
                    >
                        Topic Shown
                    </label>
                    <select
                        defaultValue={doDisplayMetrics ? "1" : "0"}
                        onChange={(e) => {
                            setTopicSelected(parseInt(e.target.value))
                        }}
                        name={'topic-id-selection'}
                        id={'topic-id-selection'}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md max-w-[230px]"
                    >
                        {getTopicIdOptions()}
                    </select>
                </div>
                <div>
                    <OpenAiResponseText text={topicSummaries.find(value => value.topic_id === topicSelected)?.insights} className={"p-1"} elementClassName={""}/>
                    <OpenAiResponseText text={topicSummaries.find(value => value.topic_id === topicSelected)?.recs} className={"p-1"} elementClassName={""}/>
                </div>
            </div>
        )
    }

    return (
        <TileRounded classArray={['h-[612px]', 'overflow-y-scroll']} tileType={"main"}>
            <div className={"flex"}>
                <div className={"flex-grow pt-2"}>
                    <span className={"text-lg"}>Topics Discussed (Jan 1st - March 8th)</span>
                </div>
                <div>
                    <label
                        htmlFor={'topic-display-selection'}
                        className="block text-sm font-medium text-gray-700"
                    >
                        Topic Display
                    </label>
                    <select
                        defaultValue={doDisplayMetrics ? "1" : "0"}
                        onChange={(e) => {
                            setDoDisplayMetrics(!!parseInt(e.target.value))
                        }}
                        name={'topic-display-selection'}
                        id={'topic-display-selection'}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md max-w-[230px]"
                    >
                        <option value={"1"}>Metrics</option>
                        <option value={"0"}>Insights</option>
                    </select>
                </div>
            </div>
            <div className={"pt-4"}>
                {doDisplayMetrics ? getCharts() : getSummary()}
            </div>
        </TileRounded>
    );
};

export default DemoTopics;
